.ant-layout-sider {
  background: $dark-blue;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 32px);
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    max-height: initial;
    position: fixed;
    overflow-y: auto;
    height: calc(100vh - 50px);
    transition: all 0.1s;
    width: 100%!important;
    max-width: 100%!important;
    & + .ant-layout {
      margin-left: 0;
    }
    &-collapsed {
      overflow-y: inherit;
    }
    &-zero-width {
      width: 0!important;
      max-width: 0!important;
    }
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
  }
  .profile {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-top: 24px;
    max-width: 100%;
    align-items: center;
    .name {
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 4px;
      display: block;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      font-weight: bold;
      color: #fff;
      margin-top: 8px;
    }
  }
}
