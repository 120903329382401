@font-face {
  font-family: 'Geometria';
  src: url('../fonts/Geometria-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/Geometria-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/Geometria.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
