.calendar-wrap {
  display: flex;
  &-sider {
    width: 340px;
    margin-right: 24px;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
    padding-right: 4px;
  }
  &-body {
    flex: 1 0 auto;
    max-height: calc(100vh - 80px);
  }
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  .calendar-body {
    overflow-y: auto;
    height: calc(100vh - 144px);
    padding-right: 4px;
    & > .fc {
      height: 100%;
    }
  }
  @media (max-width: 1100px) {
      flex-direction: column;
      &-sider {
        width: 100%;
        display: flex;
        margin-bottom: 32px;
        &>div {
          width: 50%;
          &:first-child {
            margin-right: 12px;
          }
          &:last-child {
            margin-left: 12px;
          }
        }
      }
  }
  @media (max-width: 767px) {
    &-sider {
      display: block;
      width: 100%;
      margin: 0 0 24px;
      &>div {
        width: 100%;
        margin: 0!important;
      }
    }
    &-head {
      margin: 16px 0;
      flex-wrap: wrap;
      .ant-select {
        width: 100%!important;
        margin: 0 0 12px;
      }
    }
  }
}
.fc {
  background: #F7F8FC;
  border-radius: 16px;
  overflow-x: hidden;
  .fc-view-harness {
    max-height: calc(100vh - 144px);
  }
  .fc-daygrid-day.fc-day-today,.fc-timegrid-col.fc-day-today {
    background: #E9E7F2;
  }
  &.fc-theme-standard {
    td, th {
      border-color: #A7B9CF;
      font-weight: normal;
    }
    .fc-scrollgrid {
      border: 0;
    }
  }
  .fc-timegrid-axis-cushion, .fc-timegrid-slot-label-cushion {
    font-size: 12px;
    line-height: 15px;
    color: $dark-blue;
  }
  .fc-col-header-cell-cushion,.fc-daygrid-day-number {
    color: $dark-blue;
  }
  .fc-day-today {
    .fc-col-header-cell-cushion {
      color: $red;
    }
  }
  .fc-col-header-cell-cushion {
    padding: 8px 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .fc-scrollgrid-section-header {
    & > th {
      border: 0;
    }
  }
  .fc-scrollgrid-section-body {
    & > td {
      border: 0;
    }
  }
  .fc-daygrid-event {
    white-space: normal;
    border-radius: 0;
  }
  .fc-v-event,
  .fc-daygrid-dot-event {
    position: relative;
    border: 0;
    background: rgba(255, 211, 79, 0.1);
    box-shadow: none;
    padding: 6px 6px 6px 8px;
    border-radius: 0;
    &:after {
      content: '';
      background: #FFD34F;
      height: 100%;
      border-radius: 0;
      width: 2px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .fc-event-title {
      color: #2B3D4F;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
    }
    .fc-daygrid-event-dot {
      display: none;
    }
    &.consultation {
      background: rgba(43, 61, 78, 0.1);
      &:after {
        background: #2B3D4E;
      }
    }
  }
  .fc-daygrid-event-dot {
    border-color: $green;
  }
  .fc-daygrid-day-number {
    padding: 2px 4px 0;
    font-size: 12px;
  }
}
.ant-picker-calendar-mini {
  .ant-picker-content th {
    line-height: 14px;
  }
  .ant-picker-cell {
    padding: 1px 0;
  }
}
.ant-picker-calendar {
  .ant-picker-panel {
    padding-top: 8px;
    .ant-picker-body {
      padding: 0;
    }
  }
}

.modal-webinar,
.modal-consultation,
.modal-promotion-request {
  .ant-modal {
    max-width: 90%;
  }
  .ant-modal-header {
    display: flex;
    background: #FFD34F;
    align-items: center;
    .ant-modal-title {
      color: #fff;
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      text-transform: none;
    }
  }
  .ant-modal-close-x {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-consultation {
  .ant-modal-header {
    background: #2B3D4E;
  }
}

.cancel-btn {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  padding: 10px 16px;
  color: #2B3D4E;
  border: none;
}

.modal-promotion-request {
  .ant-modal-header {
    background: #8C4484;
  }
}

.modal-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  svg {
    margin-right: 8px;
    min-width: 24px;
  }
  small {
    color: #A7B9CF;
    margin-left: 8px;
  }
}
