.layout-box {
  min-height: 100vh;
  padding: 16px 16px 16px 0;
  margin: 0 auto;
  position: relative;
  background: $dark-blue;
  @media (max-width: 767px) {
    padding: 50px 0 0;
  }
}

.highcharts-container {
  max-width: 100%;
  svg {
    max-width: 100%;
  }
}

.content-box {
  padding: 24px;
  border-radius: 16px;
  @media (max-width: 1200px) {
    padding: 16px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
  }
}

.hide-xs {
  @media (max-width: 767px) {
    display: none;
  }
}
.hide-md {
  display: none;
  @media (max-width: 767px) {
    display: inline-block;
  }
}
.dropdown-profile {
  position: fixed!important;
  z-index: 9999999999;
}
