.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) {
  .ant-select-selector {
    box-shadow: none;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  content: none;
}

.ant-select-single:not(.ant-select-customize-input) {
  .ant-select-selector {
    height: 40px;
  }
}

.ant-btn-primary {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.ant-btn-default {
  color: $dark-blue;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  border: 0;
}

.document-link {
  color: #000;

  .anticon {
    margin-right: 12px;
    @media (max-width: 767px) {
      margin-right: 4px;
    }
  }
}

.ant-btn-link span {
  text-decoration: underline;
}

.btn-document {
  margin-top: 26px;
  background: #2B3D4F;
  border-color: #2B3D4F;

  &:hover {
    background: #2B3D4F;
    border-color: #2B3D4F;
  }
}

.modal-document {
  .ant-modal {
    max-width: 470px;
  }

  .ant-modal-header {
    background: #2B3D4F;

    .ant-modal-title {
      color: #fff;
    }
  }

  .ant-modal-close-icon {
    color: #fff;
  }
  .ant-modal-content {
    .ant-upload {
      .ant-upload-text {
        margin: 0 32px;
        margin-bottom: 4px;
      }
      .ant-upload-hint {
        margin: 0 12px;
      }
    }
  }
}
