body {
    background: #2b3d4f;
    .ant-menu-inline-collapsed-tooltip{
        display: none;
    }
    .ant-input:not(textarea) {
        border-radius: 30px;
    }
    textarea.ant-input {
        border-radius: 12px;
        padding: 8px 16px;
    }
    .ant-btn-lg {
        padding: 0 24px;
    }
    .ant-btn-link {
        padding: 0;
    }
    .ant-checkbox-disabled.ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #418FDE;
            &:after{
                border-color: #f5f5f5;
                opacity: 0.7!important;
            }
        }
    }
    .ant-modal-content {
        border-radius: 12px;
        .ant-modal-header {
            border-radius: 12px 12px 0 0;
            @media (max-width: 767px) {
                padding: 16px 40px 16px 24px;
            }
        }
        .ant-modal-title {
            text-transform: uppercase;
            font-weight: 300;
        }
    }
    .ant-table-column-title {
        font-weight: normal;
        font-size: 13px;
        color: #333;
        text-transform: uppercase;
    }
    .ant-table-placeholder {
        border: 0;
    }
    #root {
        .ant-spin-spinning {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -10px;
            margin-top: -10px;
        }
        .calendar-wrap {
            .ant-spin-spinning {
                left: initial;
            }
        }
    }
    .ant-layout.ant-layout-has-sider {
        .left-menu + .ant-layout {
            margin-left: 200px;
            @media (max-width: 767px) {
                margin-left: 0;
            }
        }
        & > .ant-layout {
            //overflow-x: visible;
            margin-left: 120px;
            @media (max-width: 767px) {
                margin-left: 0;
            }
        }
    }

    .ant-input-affix-wrapper {
        padding: 0;
        position: relative;
        border-radius: 30px;
        .ant-input-prefix {
            position: absolute;
            top: 50%;
            z-index: 2;
            display: flex;
            align-items: center;
            color: rgba(0,0,0,.65);
            line-height: 0;
            transform: translateY(-50%);
            left: 12px;
        }
        input.ant-input {
            padding: 9px 16px 9px 35px;
        }
    }

    .ant-input-search.ant-input-affix-wrapper {
        border: none;
        .ant-input-suffix {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
        .ant-input-search-icon:before {
            display: none;
        }
        input {
            padding: 4px 30px 4px 11px;
            border: 1px solid #d9d9d9;
        }
    }

    .ant-form {
        width: 100%;
    }

    .ant-tabs-nav-container:not(.ant-tabs-nav-container-scrolling) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .ant-form-item-control-wrapper {
        width: 100%;
    }

    .table-wrap {
        overflow-x: auto;
        width: 100%;
    }
}

.ant-modal-wrap {
    .ant-form-item-control-input-content {
        // flex: inherit;
    }
}

@media (min-width: 451px) and (max-width: 767px) {
    body {
        .carrot-messenger-frame-container.carrotquest-messenger-line-right {
            width: calc(100vw - 16px - 16px) !important;
            max-height: none !important;
            height: calc(100% - 16px - 40px - 16px) !important;
            margin-right: -11px !important;
            bottom: 16px !important;
        }
    }
}

@media (min-width: 768px) {
    body {
        .carrot-messenger-frame-container {
            width: calc(100vw - 120px - 16px) !important;
            max-height: none !important;
            height: calc(100% - 16px - 16px) !important;
            margin-right: -11px !important;
            bottom: 16px !important;
        }
    }
}

@media (max-width: 767px) {
    body {
        .ant-message {
            z-index: 99991;
        }
        .ant-card-body {
            padding: 0;
        }
        .ant-modal-wrap {
            z-index: 99990;
            .ant-modal {
                top: 30px;
                max-width: 90%;
            }
            .ant-modal-body {
                padding: 15px;
            }
            .ant-form-item-label > label {
                height: auto;
                align-items: baseline;
            }
            .ant-steps-horizontal.ant-steps-label-horizontal {
                flex-direction: row;
                justify-content: space-between;
            }
            .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
                flex: 0;
            }
            .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
                display: inherit;
            }
            .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {

            }
            .ant-form-item-control-input-content {
                .row-wrap {
                    & > div:first-child {
                        width: 100%;
                        .ant-form-item {
                            padding-right: 8px;
                        }
                    }
                }
                .ant-form-item {
                    // width: auto;
                }
            }
        }
        .ant-list-item{
            flex-direction: column;
            align-items: normal;
        }
        .ant-card-head-title {
            white-space: inherit;
            padding: 0 0 16px;
        }
        .no-phone {
            display: none;
        }
        .video {
            & > div, & > div > div {
                width: 100% !important;
            }
        }
        .ant-layout-sider-zero-width-trigger {
            background: #efefef;
            top: 4px;
            right: inherit;
            width: 39px;
            border: 0;
            border-left: 0;
            &:hover {
                background: #efefef;
            }
        }
        .bar1, .bar2, .bar3 {
            display: block;
            width: 25px;
            height: 3px;
            background-color: #3e3e3e;
            margin: 5px 0;
            transition: 0.4s;
        }
        .ant-layout-header {
            .ant-menu {
                display: none;
                transform: translate3d(-100%,0px,0px);
                transition: all 0.5s ease 0s;
                height: 100%;
                z-index: 100;
            }
            &.header-collapsed {
                .ant-menu {
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    position: fixed;
                    transform: none;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    left: 0;
                    top: 0;
                    margin: 0;
                    bottom: 0;
                    .ant-menu-submenu {
                        display: none !important;
                        border: 0;
                        height: 0;
                        opacity: 0;
                        overflow: hidden;
                    }
                    li.ant-menu-item {
                        display: inline-flex !important;
                        a:before {
                            display: none;
                        }
                    }
                }
                .toggle {
                    & > span {
                        transform: rotate(45deg);
                        &:before {
                            top: 0;
                            transform: rotate(0);
                        }
                        &:after {
                            top: 0;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
        .ant-layout > .ant-layout {
            position: relative;
        }
        .tab-collapsed {
            .toggle {
                left: 192px;
            }
            .ant-tabs-left-bar.ant-tabs-card-bar {
                flex: 0 0 200px;
                max-width: 200px;
                min-width: 200px;
                width: 200px;
            }
        }
        .ant-list-item-action {
            margin-left: 60px;
        }
        .hide-xs {
            display: none;
        }
        .ant-group {
            .ant-btn {
                img {
                    max-width: none;
                }
            }
        }
    }
}

.ant-popover {
    max-width: 240px;
}

.modal-checklist {
    .ant-modal-header {
        background: #2B3D4F;
        .ant-modal-title {
            font-weight: bold;
            text-transform: none;
            color: #fff;
        }
    }
    .anticon-close {
        color: #fff;
    }
    .head-result {
        color: #2B3D4F;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        padding-bottom: 12px;
    }
    .table-star {
        padding-bottom: 16px;
        .ant-form-item-label {
            white-space: normal;
        }
        .ant-col-md-4 {
            flex: 0 0 100px;
            max-width: 100px;
            position: static;
        }
        .ant-col-md-20 {
            padding-right: 8px;
            flex: 1 1 auto;
            max-width: calc(100% - 100px);
            &.ant-form-item-label {
                text-align: left!important;
            }
        }
        .ant-alert-info {
            background: transparent;
            margin-bottom: 20px;
            .ant-alert-message {
                color: rgba(0, 0, 0, 0.65);
                font-size: 13px;
            }
        }
    }
    .row-item {
        .ant-form-item {
            margin: 0;
            position: relative;
            padding: 5px 0px;
            .ant-form-item-explain {
                position: absolute;
                font-size: 10px;
                left: 0;
                bottom: 2px;
                min-height: auto;
            }
        }
        & > div {
            border-top: 1px solid #A7B9CF;
        }
    }
    .table-result {
        .ant-row {
            margin-top: -1px;
            div {
                border: 1px solid #A7B9CF;
                padding: 12px;
                margin-left: -1px;
            }
            p {
                margin-bottom: 4px;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                color: #2b3d4f;
            }
        }
        .ant-row:first-child {
            font-size: 14px;
            font-weight: 700;
            color: #2B3D4F;
            div {
                text-align: center;
                &:nth-child(1) {
                    background: rgba(84, 158, 56, 0.20);
                }
                &:nth-child(2) {
                    background: rgba(255, 211, 79, 0.20);
                }
                &:nth-child(3) {
                    background: rgba(245, 77, 46, 0.20);
                }
            }
        }
    }
}
