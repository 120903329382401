@import "variables";

html body {
  background: #fff;
  font-family: $font;
}
.invite-registration-wrapper {
  background: #fff;
  height: 100%;
  .container {
    max-width: 800px;
    margin: 0 auto;
    @media (max-width: 767px) {
      padding: 0 16px;
    }
  }
  .wrap {
    padding: 40px 0;
  }
  .header {
    width: 100%;
    background: $dark-blue;
    .container {
      display: flex;
      align-items: center;
      height: 80px;
      @media (max-width: 767px) {
        justify-content: center;
      }
    }
  }
  h1 {
    color: $dark-blue;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 30px;
    svg {
      margin-right: 16px;
    }
    @media (max-width: 767px) {
      font-size: 30px;
    }
  }
  .ant-radio-wrapper {
    color: $dark-blue;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  .ant-checkbox-wrapper {
    display: flex;
    color: $dark-blue;
    font-size: 16px;
    line-height: 22px;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
    .ant-checkbox {
      height: 24px;
      width: 24px;
      top: 0;
      & + span {
        padding: 0 0 0 16px;
      }
      &:hover {
        .ant-checkbox-inner {
          border-color: $dark-blue;
        }
      }
    }
    .ant-checkbox-input,.ant-checkbox-inner {
      width: 24px;
      height: 24px;
    }
  }
  .ant-form-item-has-error {
    .ant-form-item-control-input {
      .ant-input {
        border-color: #ff4d4f;
      }
    }
  }
  .ant-form-item-control-input {
    .ant-input {
      min-height: 48px;
      font-size: 20px;
      border: 1px solid #EDEDED;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  .ant-form-item-label {
    text-align: left;
    label {
      color: $dark-blue;
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 8px;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        height: initial;
      }
    }
    label::before {
      content: none !important;
    }
    .error {
      color: $red;
      font-size: 12px;
      position: absolute;
      @media (max-width: 767px) {
        margin-top: -6px;
      }
    }
  }
  .ant-form-item-explain {
    color: $red;
    font-size: 12px;
  }
  input {
    background: #EDEDED !important;
    border: none;
    border-radius: 0 !important;
    font-weight: bold;
    color: $dark-blue;
  }
  .ant-checkbox-inner {
    background: #EDEDED;
    border: none;
    border-radius: 0 !important;
  }
  .ant-form-item {
    margin-bottom: 20px;
  }
  button[type=submit] {
    background: $red;
    color: #fff;
    border: none;
    border-radius: 0;
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border-color: $dark-blue;
    }
    .ant-checkbox-inner::after {
      border-color: $dark-blue;
      top: 11px;
      left: 7px;
      width: 7px;
      height: 12px;
    }
  }
  .btn-control {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    min-height: 56px;
  }
  .ant-alert {
    border: 0;
    border-radius: 0;
    font-size: 20px;
    padding: 16px 16px 16px 44px;
    &.ant-alert-with-description {
      padding-left: 60px;
    }
  }
  .ant-alert-icon {
    top: 20px;
  }
  .ant-alert-description {
    font-size: 18px;
  }
  .ant-alert-error {
    background-color: #fff2f0;
    border-radius: 0;
    color: #ffccc7;
    .ant-alert-message {
      color: $red;
    }
  }
  .ant-alert-success {
    .ant-alert-message {
      color: $green;
    }
    &.ant-alert-with-description {
      .ant-alert-message {
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
  .ant-radio-group {
    .ant-radio-button-wrapper {
      background: #ededed;
      color: $dark-blue;
      border-color: #ededed;
      border-radius: 0;
      height: 52px;
      line-height: 52px;
      font-size: 18px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: $dark-blue;
      background: $dark-blue;
      color: #fff;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: transparent;
    }
  }
  .ant-form-item-extra {
    padding-top: 8px;
  }
}
